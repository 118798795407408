import React from "react";
import { Col, Row } from "react-bootstrap";

import { Title, Button, Section, Box } from "../../components/Core";
import FlopFormattedMessageWrapper from "../../components/Flop/FlopFormattedMessageWrapper";

import flopGtoLogoDarkPng from "../../assets/image/flop/flop-gto-logo-dark.png";

const Gto = () => (
  <Section bg="light">
    <Box px={[1, 6]}>
      <Col>
        <Row className="justify-content-center mb-3">
          <img
            src={flopGtoLogoDarkPng}
            style={{ width: "362px" }}
            alt="Gto Logo dark"
          />
        </Row>
        <Row className="justify-content-center mb-4">
          <Title color="dark" textAlign="center">
            <FlopFormattedMessageWrapper id="home.gto.title" />
          </Title>
        </Row>
        <Row className="justify-content-center">
          {/* How to get current local */}
          <a href="/en/flop-gto#pricing">
            <Button>
              <FlopFormattedMessageWrapper id="home.gto.cta" />
            </Button>
          </a>
        </Row>
      </Col>
    </Box>
  </Section>
);

export default Gto;
