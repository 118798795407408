import React from "react";

import Hero from "../sections/flop-landing/Hero";
import PageWrapper from "../components/PageWrapper";
import Products from "../sections/flop-landing/Products";
import Gto from "../sections/flop-landing/Gto";
import Seo from "../components/Seo/seo";

const IndexPage = () => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Seo
          title="First Land Of Poker - Home"
          desc="First land of poker is a poker suit tools, in order to improve the poker room."
        />
        <Hero />
        <Products />
        {/* <Tips /> */}
        <Gto />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
