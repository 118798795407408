import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby-plugin-intl";

import { Box, Section, Title } from "../../components/Core";
import FlopFormattedMessageWrapper from "../../components/Flop/FlopFormattedMessageWrapper";

import flopPmLogoDarkPng from "../../assets/image/flop/flop-pm-logo-dark.png";
import flopAppLogoDarkPng from "../../assets/image/flop/flop-app-logo-dark.png";
import flopGtoLogoDarkPng from "../../assets/image/flop/flop-gto-logo-dark.png";

const ContentCard = ({
  color = "primary",
  className,
  image,
  children,
  ...rest
}) => (
  <Box
    bg="#F7F7FB"
    border="1px solid"
    borderColor="border"
    p="20px"
    borderRadius={10}
    className={`d-flex align-items-center justify-content-center ${className}`}
    {...rest}
    css={`
      transition: all 0.3s ease-out;
      &:hover {
        box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow};`};
      }
    `}
  >
    <img className="img-fluid" src={image} alt="Flop detailed Product logo" />
  </Box>
);

const Products = () => {
  return (
    <Section bg="greyBg" className="position-relative">
      <Box px={[1, 1, 1, 1, 6]}>
        <Box className="mb-3">
          <Title color="dark" textAlign="center">
            <FlopFormattedMessageWrapper id="home.products.title" />
          </Title>
        </Box>
        <Container>
          <Row className="justify-content-center">
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="50"
            >
              <Link to="/flop-pm">
                <ContentCard image={flopPmLogoDarkPng} />
              </Link>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="50"
            >
              <Link to="/flop-app">
                <ContentCard image={flopAppLogoDarkPng} />
              </Link>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="50"
            >
              <Link to="/flop-gto">
                <ContentCard image={flopGtoLogoDarkPng} />
              </Link>
            </Col>
          </Row>
        </Container>
      </Box>
    </Section>
  );
};

export default Products;
