import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { useIntl } from "gatsby-plugin-intl";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import FlopPresentationVid from "../../assets/videos/flop-app-video-presentation.mp4";

import { Link } from "gatsby";

const VidRight = styled.video`
  width: 100%;
`;

const Hero = () => {
  const intl = useIntl();
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg="black" className="position-relative">
        <Box px={[1, 1, 1, 5, 6]} pt={[5, 3, 3, 3, 5]}>
          <Col>
            <Row className="justify-content-center align-items-center">
              <Col lg="6" md="8" sm="9" className="order-lg-2">
                <div className="text-center text-lg-right position-relative">
                  <div>
                    <VidRight autoPlay muted loop>
                      <source src={FlopPresentationVid} type="video/mp4" />
                      Sorry, your browser doesn't support embedded videos.
                    </VidRight>
                  </div>
                </div>
              </Col>
              <Col lg="6" className="order-lg-1" style={{paddingLeft: 20, paddingRight: 20}}>
                <div
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <Box pr={5} pt={[null]}>
                    <Title color="light" variant="hero">
                      {intl.formatMessage({
                        id: "home.hero.title",
                      })}
                    </Title>
                    <Text color="light" mb={4}>
                      {intl.formatMessage({
                        id: "home.hero.subtitle",
                      })}
                    </Text>
                    <div className="d-flex flex-column align-items-start">
                      <Link to="/about-us">
                        <Button mb={3}>
                          {intl.formatMessage({
                            id: "home.hero.cta",
                          })}
                        </Button>
                      </Link>
                    </div>
                  </Box>
                </div>
              </Col>
            </Row>
          </Col>
        </Box>
      </Section>
    </>
  );
};

export default Hero;
